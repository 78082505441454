import { useCallback, useState, useMemo, useEffect } from 'react';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import { getCurrency } from '@fh-components/fh-js-api-core/store/slices/currencySlice';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import {
	getCartDataLoading,
	createLookInCart,
	updateCartIds,
	updateCartCount,
} from '@fh-components/fh-js-api-core/store/slices/cartSlice';
import { cartInfoSelector, hasSmartBannerSelector } from '@/store';
import Link from 'next/link';
import clsx from 'clsx';
import { Button } from '@/components';
import getLinkProps from '@/lib/getLinkProps';
import ecommerce from '@/seo/Ecommerce';
import Product from './Product';
import Cookie from 'js-cookie';

import styles from './ProductsModal.module.scss';

const orderAllLook = 'Заказать весь образ';
const orderAllGoods = 'Заказать все товары';

const ProductsModal = ({ showDialog, onDismiss, products: goods, loading, isLook = false }) => {
	const currency = useSelector(getCurrency);
	const cartLoading = useSelector(getCartDataLoading);
	const { ids, count } = useSelector(cartInfoSelector);
	const hasSmartBanner = useSelector(hasSmartBannerSelector);

	const [products, setProducts] = useState([]);

	const dispatch = useDispatch();

	const [isConfirmOrder, setIsConfirmOrder] = useState(false);
	const [sizeChoosenModalState, setSizeChosenModalState] = useState({
		selectedSlugs: [],
		validated: false,
	});
	const [cartHref, cartAs] = getLinkProps({ type: 'cart' });
	const currentSlugs = useMemo(() => products.map(({ slug }) => slug), [products]);

	const getInitialSizeChosenModalState = productItems =>
		productItems.map(item => {
			if (item.attributes.some(attr => attr.name === 'Размер производителя')) {
				return null;
			}

			return item.model.slug;
		});

	useEffect(() => {
		if (products.length) {
			setSizeChosenModalState(prev => {
				const newSelectedSlugs = [...getInitialSizeChosenModalState(products)];

				for (let i = 0; i < newSelectedSlugs.length; i++) {
					if (prev.selectedSlugs[i]) {
						newSelectedSlugs[i] = prev.selectedSlugs[i];
					}
				}

				return {
					...prev,
					selectedSlugs: newSelectedSlugs,
					validated: newSelectedSlugs.every(value => value),
				};
			});
		}
	}, [products]);

	const setSizeLookModal = (index, sizeState) => slug => {
		setSizeChosenModalState(prev => {
			const newState = [...prev.selectedSlugs];

			newState[index] = slug;

			const validated = newState.every(value => value);

			return {
				selectedSlugs: newState,
				validated,
			};
		});
	};

	const updatedCurrentSlugs = useCallback(
		(slug, index) => {
			currentSlugs.splice(index, 1, slug);
		},
		[currentSlugs],
	);

	const removeProduct = useCallback(
		index => {
			setProducts(prev => prev.filter((_, i) => i !== index));
			currentSlugs.splice(index, 1);

			const newSizeModalState = [...sizeChoosenModalState.selectedSlugs];

			newSizeModalState.splice(index, 1);
			setSizeChosenModalState({
				...sizeChoosenModalState,
				selectedSlugs: newSizeModalState,
				validated: newSizeModalState.every(state => state),
			});
		},
		[currentSlugs, sizeChoosenModalState],
	);

	useEffect(() => {
		if (goods.length) {
			setProducts(goods);
		}
	}, [goods]);

	const onConfirmOrder = useCallback(() => {
		setIsConfirmOrder(true);

		const dataArray = sizeChoosenModalState.selectedSlugs.filter(product => !ids.includes(product));
		const mindboxDeviceUUID = Cookie.get('mindboxDeviceUUID');

		dispatch(
			createLookInCart({
				products: dataArray.map(product => {
					return {
						id: product,
						quantity: 1,
					};
				}),
				meta: {
					pageName: isLook ? orderAllLook : orderAllGoods,
				},
				mindboxDeviceUUID: mindboxDeviceUUID || '',
				onSuccess: props => {
					onDismiss();
					dispatch(updateCartIds({ ids: [...ids, ...dataArray] }));
					dispatch(updateCartCount({ count: count + dataArray.length }));
				},
			}),
		);

		const changeProductDataSize = data => {
			try {
				let attributes = [];

				for (let i = 0; i < data.attributes.length; i++) {
					const attribute = data.attributes[i];

					if (attribute.name === 'Основной цвет' || attribute.name === 'Размер производителя') {
						const value = attribute.options[0].name;

						attributes.push({ value });
					}
				}

				return {
					...data,
					quantity: 1,
					switchable_attributes: attributes,
				};
			} catch (error) {
				console.warn('size value was not sent correctly');
				console.warn(error);

				return data;
			}
		};

		const newProducts = products.map(item => {
			const newData = changeProductDataSize(item);

			return { ...newData };
		});

		ecommerce.viewItemList(
			newProducts,
			currency,
			isLook ? orderAllLook : orderAllGoods,
			'add_to_cart',
		);
	}, [sizeChoosenModalState, products, dispatch, currency, count, ids, isLook]);

	return (
		<DialogOverlay
			isOpen={showDialog}
			aria-label={undefined}
			className={clsx(styles.root, hasSmartBanner && styles.rootBanner)}
		>
			<DialogContent className={clsx(styles.dialog)}>
				<div className={styles.header}>
					<span className={styles.title}>Выберите ваши размеры</span>
					{isConfirmOrder ? (
						<Link href={cartHref} as={cartAs} passHref>
							<Button classNames={{ general: styles.button }} type='primary' loading={cartLoading}>
								Перейти в корзину
							</Button>
						</Link>
					) : (
						<Button
							type='primary'
							onClick={onConfirmOrder}
							classNames={{ general: styles.button }}
							disabled={!sizeChoosenModalState.validated}
						>
							{isLook ? orderAllLook : orderAllGoods}
						</Button>
					)}
					<img
						onClick={onDismiss}
						className={styles.close}
						src='/images/svg/close.svg'
						alt='close'
					/>
				</div>

				<div className={styles.products}>
					{products.length ? (
						products.map((product, index) => (
							<Product
								key={product.slug}
								product={product}
								index={index}
								removeProduct={removeProduct}
								loading={loading}
								length={products.length}
								sizeChoosenModalState={sizeChoosenModalState.selectedSlugs}
								updatedCurrentSlugs={updatedCurrentSlugs}
								setSizeLookItem={setSizeLookModal(index, sizeChoosenModalState)}
								isLooks
							/>
						))
					) : (
						<div className={styles.skeleton}>
							<div className={styles.skeletonBlock} key='look-skeleton-1'>
								<div className={styles.skeletonImage}>
									<Skeleton
										key='skeleton-shop1'
										count={1}
										height='100%'
										width='100%'
										style={{ marginBottom: '24px' }}
									/>
								</div>
								<div className={styles.skeletonDescription}>
									<Skeleton
										key='skeleton-shop2'
										count={1}
										height='24px'
										width='60px'
										style={{ marginBottom: '8px' }}
									/>
									<Skeleton
										key='skeleton-shop3'
										count={1}
										height='48px'
										width='100%'
										style={{ marginBottom: '8px' }}
									/>
									<Skeleton
										key='skeleton-shop3'
										count={1}
										height='24px'
										width='80px'
										style={{ marginBottom: '8px' }}
									/>
								</div>
								<div className={clsx(styles.skeletonDescription, styles.skeletonSize)}>
									<Skeleton
										key='skeleton-shop2'
										count={1}
										height='32px'
										width='90%'
										style={{ marginBottom: '16px' }}
									/>
									<Skeleton
										key='skeleton-shop3'
										count={1}
										height='24px'
										width='25%'
										style={{ marginBottom: '8px' }}
									/>
								</div>
							</div>
							<div className={styles.skeletonBlock} key='look-skeleton-2'>
								<div className={styles.skeletonImage}>
									<Skeleton
										key='skeleton-shop1'
										count={1}
										height='100%'
										width='100%'
										style={{ marginBottom: '24px' }}
									/>
								</div>
								<div className={styles.skeletonDescription}>
									<Skeleton
										key='skeleton-shop2'
										count={1}
										height='24px'
										width='60px'
										style={{ marginBottom: '8px' }}
									/>
									<Skeleton
										key='skeleton-shop3'
										count={1}
										height='48px'
										width='100%'
										style={{ marginBottom: '8px' }}
									/>
									<Skeleton
										key='skeleton-shop3'
										count={1}
										height='24px'
										width='80px'
										style={{ marginBottom: '8px' }}
									/>
								</div>
								<div className={clsx(styles.skeletonDescription, styles.skeletonSize)}>
									<Skeleton
										key='skeleton-shop2'
										count={1}
										height='32px'
										width='90%'
										style={{ marginBottom: '16px' }}
									/>
									<Skeleton
										key='skeleton-shop3'
										count={1}
										height='24px'
										width='25%'
										style={{ marginBottom: '8px' }}
									/>
								</div>
							</div>
						</div>
					)}
				</div>
			</DialogContent>
		</DialogOverlay>
	);
};

export default ProductsModal;
