import React, { useMemo, useCallback, useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { Loader, Price, ProductFeature } from '@/components';
import getLinkProps from '@/lib/getLinkProps';

import styles from './ProductsModal.module.scss';

const ProductConfig = dynamic(() => import('../../components/productConfig'), {
	loading: () => <Loader />,
});

const Product = ({
	product,
	index,
	removeProduct,
	length,
	updatedCurrentSlugs,
	sizeChoosenModalState,
	setSizeLookItem,
	key,
}) => {
	const {
		brand,
		name,
		old_price,
		price,
		attributes = [],
		model,
		media = [],
		price_diff_percent,
	} = product;

	const [currentSlug, setCurrentSlug] = useState(null);
	const switchAttributes = useMemo(() => attributes.filter(attr => attr.type === 'switch') || [], [
		attributes,
	]);

	const [actualPrice, setActualPrice] = useState(price);
	const [href, as] = getLinkProps({ type: 'product', value: model.slug });
	console.log(as);

	useEffect(() => {
		setActualPrice(price);
	}, [price]);

	const handleSetCurrentSlug = useCallback(
		newSlug => {
			updatedCurrentSlugs(newSlug, index);
			setCurrentSlug(newSlug);
		},
		[updatedCurrentSlugs, index],
	);

	const handleSetActualPrice = useCallback(
		newPrice => {
			setActualPrice(newPrice ?? price);
		},
		[price],
	);

	return (
		<div className={styles.main} key={key}>
			<a href={as} target='_blank' className={styles.leftSection}>
				<img className={styles.image} src={media[0]?.x2} alt={name} />
				<div className={styles.description}>
					<div className={styles.brand}>{brand}</div>
					<div className={styles.name}>{name}</div>
					<div className={styles.priceBlock}>
						{!!price_diff_percent && actualPrice !== old_price && (
							<div className={styles.discountPercent}>
								<ProductFeature title={`${price_diff_percent}%`} styledType='sale' />
							</div>
						)}
						<div className={styles.price}>
							<Price price={actualPrice} oldPrice={old_price} mod='face-item' isLookProduct />
						</div>
					</div>
				</div>
			</a>
			<div className={styles.rightSection}>
				<ProductConfig
					switchAttributes={switchAttributes}
					currentSlug={currentSlug}
					slug={model.slug}
					actualPrice={price}
					setActualPrice={handleSetActualPrice}
					setCurrentSlug={handleSetCurrentSlug}
					setSizeLookItem={setSizeLookItem}
					sizeChoosenModalState={sizeChoosenModalState}
					data={product}
					isModal
					isLook
					noBucketButton
					index={index}
					productInfo={{
						pageName: 'Идеи для образа',
						indexCard: index,
					}}
				/>
			</div>
			{length > 1 && (
				<img
					onClick={() => removeProduct(index)}
					className={styles.removeProduct}
					src='/images/svg/close.svg'
					alt='remove'
				/>
			)}
		</div>
	);
};

export default Product;
